import React from 'react';
import logo from './logo.svg';
import './App.css';
import {ApolloProvider, useQuery} from "@apollo/react-hooks";
import ApolloClient from "apollo-boost";
import {gql} from 'apollo-boost';

const client = new ApolloClient({
  uri: 'https://48p1r2roz4.sse.codesandbox.io'
})

const EXCHANGE_RATES = gql`
    {
        rates(currency: "USD") {
            currency,
            rate
        }
    }
`;

function ExchangeRates() {
    const {loading, error, data} = useQuery(EXCHANGE_RATES);

    if (loading) return <p>Loading...</p>
    if (error) return <p>Error :(</p>

    return data.rates.map(({currency,  rate}) => (
        <div key={currency}>
            <p>{currency}: {rate}</p>
        </div>
    ))

}

const App = () => (
    <ApolloProvider client={client}>
      <div>
        <h2>My first Apollo app</h2>
          <ExchangeRates/>
      </div>
    </ApolloProvider>
)

export default App;
